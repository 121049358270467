/*
 * Conditionally renders a link. If an href attribute is passed in,
 * an unstyled anchor tag is used. Otherwise, a div is displayed.
 */

import styled from "@emotion/styled"
import { AnchorHTMLAttributes } from "react"

type OptionalLinkProps = AnchorHTMLAttributes<HTMLAnchorElement>

const OptionalLink = ({ href, ...props }: OptionalLinkProps) => (
    <UnstyledLink as={href ? "a" : "div"} href={href || undefined} {...props} />
)

export default OptionalLink

const UnstyledLink = styled.a`
    text-decoration: none;
    color: inherit;
`
