import { gql, useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import {
    AsyncMultiSelect,
    AsyncMultiSelectProps,
    AsyncSingleSelect,
    AsyncSingleSelectProps,
    Text,
} from "@pallet-hq/hegel"
import NextLink from "components/Link/NextLink"
import { CompaniesQuery } from "__generated__/CompaniesQuery"
import CompanySelectImage from "./CompanySelectImage"

export const COMPANIES_QUERY = gql`
    query CompaniesQuery($searchTerm: String!, $first: Int!) {
        companies(name: $searchTerm, first: $first) {
            edges {
                node {
                    id
                    slug
                    name
                    image
                }
            }
        }
    }
`

export type CompanySelectProps = Omit<
    AsyncSingleSelectProps | AsyncMultiSelectProps,
    "loadOptions"
> & {
    isMulti?: boolean
    hideCreateOption?: boolean
}

const CreateCompanyBanner = () => (
    <Text
        variant="uiSmall500"
        color="gray600"
        backgroundColor="gray100"
        textAlign="center"
        px="xsmall"
        py="small"
        style={{ fontStyle: "italic" }}
    >
        Don’t see your company?{" "}
        <NextLink href="/create-company" passHref>
            <StyledLink target="_blank">Create a new one</StyledLink>
        </NextLink>
    </Text>
)

const CompanySelect = (props: CompanySelectProps) => {
    const SelectComponent = props.isMulti ? AsyncMultiSelect : AsyncSingleSelect
    const companyQuery = useQuery<CompaniesQuery>(COMPANIES_QUERY, {
        variables: { first: 20, searchTerm: "" },
    })

    return (
        <SelectComponent
            defaultOptions={
                companyQuery?.data?.companies
                    ? companyQuery.data.companies.edges!.map(company => ({
                          label: company!.node!.name,
                          value: company!.node!.slug,
                          icon: (
                              <CompanySelectImage
                                  node={{ image: company!.node!.image! }}
                              />
                          ),
                      }))
                    : []
            }
            placeholder={
                props.placeholder
                    ? props.placeholder
                    : "Search for your company"
            }
            loadOptions={(inputValue: string) => {
                return companyQuery
                    .refetch({ searchTerm: inputValue, first: 20 })
                    .then(({ data }) => {
                        if (!data || !data.companies) return []
                        return data.companies.edges.map(company => ({
                            label: company!.node!.name,
                            value: company!.node!.slug,
                            icon: (
                                <CompanySelectImage
                                    node={{ image: company!.node!.image! }}
                                />
                            ),
                        }))
                    })
            }}
            banner={!props.hideCreateOption ? <CreateCompanyBanner /> : null}
            {...props}
        />
    )
}

export default CompanySelect

const StyledLink = styled.a`
    text-decoration: underline;
    color: inherit;
`
