import { useFormikContext, FormikValues } from "formik"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {
    SingleSelect,
    theme,
    ui,
    SingleSelectProps,
    CurrencyInputField,
} from "@pallet-hq/hegel"
import getSymbolFromCurrency from "currency-symbol-map"

type SalaryAndCurrencyInputProps = {
    label?: string
    description?: string
    sideDescription?: string
    supportedCurrencies: SingleSelectProps["options"]
}

const SalaryAndCurrencyInput = (props: SalaryAndCurrencyInputProps) => {
    const formikContext = useFormikContext<FormikValues>()
    const currencySigil =
        getSymbolFromCurrency(
            formikContext.values.selectedSalaryCurrency?.value?.toUpperCase()
        ) ?? "$"
    return (
        <SalaryAndCurrencyInputContainer>
            <ui.Label
                css={css`
                    width: 100%;
                `}
                label={props.label ?? "What is your expected salary?"}
                description={
                    props.description != undefined
                        ? props.description
                        : "This should only be the cash amount you are looking for and not including additional methods of compensation."
                }
                sideDescription={props.sideDescription}
            />
            <InputRowContainer>
                <SingleSelect
                    name="selectedSalaryCurrency"
                    options={props.supportedCurrencies}
                    minWidth="100px"
                    shouldCloseMenuOnScroll
                />
                <CurrencyInputField
                    name="selectedSalary"
                    label=""
                    placeholder={`${currencySigil}100,000`}
                    width="100%"
                    detectCurrencyFieldName="selectedSalaryCurrency"
                />
            </InputRowContainer>
        </SalaryAndCurrencyInputContainer>
    )
}

export default SalaryAndCurrencyInput

const SalaryAndCurrencyInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > div {
        width: 100%;
    }
`

const InputRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    gap: ${theme.space.xsmall};
`
