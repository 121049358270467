import styled from "@emotion/styled"

const UnstyledButton = ({ ...props }) => <Button {...props} />

export default UnstyledButton

const Button = styled.button`
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
`
