import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import { urlValidator } from "utils/sharedValidationSchemas"

const FIELD_NAME = "linkedinUrl" as const

const LinkedinUrlField = ({ ...props }: TextInputFieldProps) => {
    return (
        <TextInputField
            label="LinkedIn"
            placeholder="https://linkedin.com/in/steve-jobs"
            type="url"
            name={FIELD_NAME}
            {...props}
        />
    )
}

LinkedinUrlField.fieldName = FIELD_NAME
LinkedinUrlField.validators = {
    profileRequired: {
        [FIELD_NAME]: urlValidator(200)
            .matches(
                /^https?:\/\/(www\.)?linkedin.com\/in\/..*$/,
                "Must be a LinkedIn URL."
            )
            .required("Required"),
    },
    profileNotRequired: {
        [FIELD_NAME]: urlValidator(200).matches(
            /^https?:\/\/(www\.)?linkedin.com\/in\/..*$/,
            "Must be a LinkedIn URL."
        ),
    },
    companyOrProfileNotRequired: {
        [FIELD_NAME]: urlValidator(200).matches(
            /^https?:\/\/(www\.)?linkedin.com\/..*$/,
            "Must be a LinkedIn URL."
        ),
    },
}

export { LinkedinUrlField }
