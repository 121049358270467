import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import { reqMaxCharValidator } from "utils/validators"

const FIELD_NAME = "firstName" as const

const FirstNameField = (props: TextInputFieldProps) => {
    return (
        <TextInputField
            label="First name"
            placeholder="Steve"
            name={FIELD_NAME}
            {...props}
        />
    )
}

FirstNameField.fieldName = FIELD_NAME
FirstNameField.validators = {
    required: { [FIELD_NAME]: reqMaxCharValidator(100) },
}

export { FirstNameField }
