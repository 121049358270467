import styled from "@emotion/styled"
import Image from "components/Image"

type CompanySelectImageProps = {
    node: { image: string }
}

const CompanySelectImage = ({ node }: CompanySelectImageProps) => (
    <Container>
        <StyledImage width={16} height={16} src={node.image} colorSlug="red" />
    </Container>
)

export default CompanySelectImage

const Container = styled.div`
    display: inline-block;
`

const StyledImage = styled(Image)`
    display: inline-block;
`
