import { TextInputField, TextInputFieldProps } from "@pallet-hq/hegel"
import { reqMaxCharValidator } from "utils/validators"

const FIELD_NAME = "lastName" as const

const LastNameField = (props: TextInputFieldProps) => {
    return (
        <TextInputField
            label="Last name"
            placeholder="Jobs"
            name={FIELD_NAME}
            {...props}
        />
    )
}

LastNameField.fieldName = FIELD_NAME
LastNameField.validators = {
    required: { [FIELD_NAME]: reqMaxCharValidator(100) },
}

export { LastNameField }
