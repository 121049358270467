import { hasWindow } from "./window"

/**
 * Checks the browser's userAgent to determine if the device is running Mac OS.
 * Consider using feature detection instead when possible.
 * @returns {boolean}
 */
export const isMacintosh = () => {
    if (!hasWindow()) return false
    return navigator.userAgent.includes("Mac")
}

/**
 * Checks the browser's userAgent to determine if the device is running Windows.
 * Consider using feature detection instead when possible.
 * @returns {boolean}
 */
export const isWindows = () => {
    if (!hasWindow()) return false
    return navigator.userAgent.includes("Win")
}
